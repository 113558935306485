(function() {
    'use strict';

    angular
        .module('therapist')
        .constant('EpisodeResourceMaterialsConstants', {
            resources: {
                add: {
                    errorString: 'We were unable to add the resource to this episode',
                    confirmText: 'Are you sure that you would like to make this resource available?'
                },
                remove: {
                    errorString: 'We were unable to remove the resource from this episode',
                    confirmText: 'Are you sure that you would like to make this resource unavailable?'
                },
                get: {
                    errorString: 'We were unable to retrieve resources'
                }
            }
        });
})();