(function () {
    'use strict';

    angular
        .module('therapist')
        .constant('PatientPrescriptionConstants', {
            mode: {
                SAVE: 0,
                PRESCRIBE: 1,
                EXPIRE: 2
            },
            observers: {
                updateProtocol: 'PatientPrescriptionConstants.observers.updateProtocol'
            }
        });
})();