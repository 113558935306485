(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name DashboardCtrl
     * @description The dashboard controller.
     * @property {array} messages The current messages for the logged in user.
     * @property {array} recentPatients The current array of recent patients for the logged in user.
     * @property {array} lessRecentPatients An array of less recent patients for the logged in user.
     */
    angular
        .module('therapist')
        .controller('DashboardCtrl', DashboardCtrl);

    DashboardCtrl.$inject = ['$scope', '$rootScope', '$location', '$interval', 'RestResources', '_', 'Utilities', 'EpisodeChooserConfig',
        'isAssociate', 'permissionConstants', 'PermissionsHelper'];

    function DashboardCtrl($scope, $rootScope, $location, $interval, RestResources, _, Utilities, EpisodeChooserConfig,
        isAssociate, permissionConstants, PermissionsHelper) {
        var clinic = $scope.getClinic();
        var professionalId = _.get($scope.user, 'clinicProfessional.id');

        // This is the clinic ID set in the therapist sender for a treatment intelligence
        // message. Such messages should never be treated as associate messages.
        var ihhClinicId = 'ihh-clinic-id';

        $scope.title = isAssociate ? "Associate Dashboard" : "My Clinic Dashboard";
        var permissions = PermissionsHelper.getClinicProfessionalPermissions($scope.user);

        $scope.createPatientWithEpisode = function() {
            $location.path('/patients/new');
        };

        const messageFilterSystem = 'system';
        const messageFilterPatient = 'patient';
        const messageFilterStaff = 'staff';
        $scope.messageFilter = '';
        $scope.messages = [];
        $scope.extraAttributes = {
            episodeFilter: ''
        };
        
        $scope.oldest = false;
        
        var messagesLimit = 25;
        var resume = null;

        $rootScope.$on('updatePatient', setDirty);


        var eocParams = {
            clinicId: clinic.id,
            therapistId: !isAssociate ? professionalId : null,
            associateProfessionalId: isAssociate ? professionalId : null,
            active: true
        };

        var getThumbnailUrlFn = function(episode) {
            var result = episode ? $scope.getPatientThumbnailUrl(clinic, episode.patient) : null;

            return result;
        };

        $scope.config = EpisodeChooserConfig.init(clinic, getThumbnailUrlFn, eocParams, !isAssociate && clinic.leadGeneration,
            isAssociate ? professionalId : null);
        $scope.config.maintainState($scope);

        // Turned off right now experimenting with dashboard format
        $scope.config.actions.canCreatePrescription = function() {
            // return !isAssociate && permissions.includes(permissionConstants.CREATE_PRESCRIPTION);
            return false;
        };

        $scope.config.actions.isAssociate = function() {
            return isAssociate;
        };


        var messagePolling;
        $scope.getMessages = function() {
            $scope.messages = $scope.messages || [];
            $scope.messages.$resolved = false;
            $scope.messages.$promise = RestResources.getTherapistMessagesResource(clinic.id, professionalId).query(
                getMessageQueryParams(),
                function(queryResult) {
                    $scope.messages = queryResult.items;
                    $scope.messages.$resolved = true;
                    resume = queryResult.items.length >= messagesLimit ? queryResult.resume : null;
                }, function(err) {
                    RestResources.reportRestError('Could not get messages', err);
                });


            if (!angular.isDefined(messagePolling)) {
                var messagePollingRate = 5 * 60 * 1000; // every 5 minutes
                messagePolling = $interval($scope.getMessages, messagePollingRate);
            }
        };
        $scope.$on('$destroy', function() {
            $interval.cancel(messagePolling);
        });
        
        /*
         * Gets the next page of unread messages and appends them to the messages list.
         * @param {string} resume the resume token from the last query
         */
        $scope.nextPage = function() {
            $scope.messages = $scope.messages || [];
            $scope.messages.$resolved = false;
            var params = getMessageQueryParams();
            params.resume = resume;
            $scope.messages.$promise = RestResources.getTherapistMessagesResource(clinic.id, professionalId).query(
                params,
                function(queryResult) {
                    $scope.messages.push(...queryResult.items);
                    $scope.messages.$resolved = true;
                    resume = queryResult.items.length >= messagesLimit ? queryResult.resume : null;
                }, function(err) {
                    RestResources.reportRestError('Could not get messages', err);
                });
        };

        $scope.getMessages();

        $scope.gotoMessages = function(message) {
            if (message && message.episodeOfCareId && message.patientId) {
                var path = 'patients/' + message.patientId + '/episodes/' + message.episodeOfCareId;

                if (isAssociateMessage(message)) {
                    path += '/associate/';
                    path += professionalId;
                }

                if (message.clinical) {
                    path += '/tab/clinicalMessages';
                }
                else {
                    path += '/tab/messages';
                }


                $location.path(path);
            }
        };

        $scope.preventChildClick = function($event) {
            // this stops the HREF navigation
            $event.preventDefault();
            // this stops ngClick actions
            $event.stopPropagation();
        };

        $scope.readMessage = function(message) {
            RestResources.getEpisodeOfCareMessageReceiptsResource(clinic.id, message.episodeOfCareId).save({
                messageIds: [message.id]
            }, function(readMessages) {
                if (readMessages.messageIds) {
                    for (var i = 0; i < readMessages.messageIds.length; i++) {
                        // remove the message from the unread list
                        for (var j = 0; j < $scope.messages.length; j++) {
                            if ($scope.messages[j].id === readMessages.messageIds[i]) {
                                $scope.messages.splice(j, 1);
                                break;
                            }
                        }
                    }
                }
            });
        };

        /**
         * Gets the thumbnail URL for the message sender.
         * @param {type} message
         * @returns {String} the thumbnail URL for the message sender
         */
        $scope.getMessageSenderThumbnailUrl = function(message) {
            if (message.fromPatient) {
                return _.get(message, "patientSender.thumbnailUrl")
                    || _.get(message, "patientSender.person.thumbnailUrl")
                    || $scope.getDefaultPersonThumbnailUrl();
            }
            else {
                return _.get(message, "therapistSender.thumbnailUrl")
                    || _.get(message, "therapistSender.person.thumbnailUrl")
                    || $scope.getDefaultPersonThumbnailUrl();
            }
        };

        $scope.getThumbnailUrl = function(person) {
            if (person) {
                return $scope.getUserThumbnailUrl(person);
            }
        };

        /**
         * Returns whether the caller has permission to create a patient.
         * @returns {Boolean} the permission value
         */
        $scope.canCreatePatient = function() {
            return !isAssociate && permissions.includes(permissionConstants.CREATE_CLINIC_PATIENT);
        };
        
        $scope.showMoreMessagesButton = function() {
            return $scope.messages.$resolved && resume;
        };

        function setDirty() {
            $scope.config && $scope.config.setDirty();
        }

        /*
         * Answers whether an unread message in the dashboard is one from another clinic,
         * an associate message.
         */
        function isAssociateMessage(message) {
            var msgClinicId = _.get(message, 'patientSender.clinic.id');

            if (!msgClinicId) {
                msgClinicId = _.get(message, 'therapistSender.clinic.id');
            }

            return msgClinicId && msgClinicId !== clinic.id && msgClinicId !== ihhClinicId;
        }
        
        /*
         * Returns the query params object for getting messages.
         */
        function getMessageQueryParams()
        {
            var params = {};
            params.limit = messagesLimit;
            if ($scope.messageFilter === messageFilterSystem) {
                params.system = true;
            }
            else if ($scope.messageFilter === messageFilterStaff) {
                params.fromPatient = false;
                params.system = false;
            }
            else if ($scope.messageFilter === messageFilterPatient) {
                params.fromPatient = true;
                params.system = false;
            }
            
            if ($scope.oldest) {
                params.order = 'sent';
            }
            
            return params;
        }
    }
})();
