(function() {
    'use strict';

    angular
        .module('therapist')
        .constant('ResourceMaterialsConstants', {
            resources: {
                name: {
                    singular: 'Resource',
                    plural: 'Resources'
                },
                category: 'Category',
                tags: 'Resources Category',
                form: 'ResourceMaterialsVm.ResourceMaterialsForm',
                link: '/research/resource-materials/{0}',
                query: {
                    errorString: 'Could not get resource list'
                },
                create: {
                    errorString: 'Could not create this resource',
                    keyword: 'new'
                },
                update: {
                    errorString: 'Could not update this resource'
                },
                delete: {
                    errorString: 'Could not delete this resource',
                    confirmString: 'Are you sure you want to delete this resource?'
                },
                get: {
                    errorString: 'Could not retrieve this resource'
                },
                copy: {
                    errorString: 'Could not copy this resource'
                }
            },
            media: {
                types: {
                    thumbnail: 'thumbnail',
                    video: 'video',
                    pdf: 'PDF'
                },
                upload: {
                    errorString: 'Could not upload {0}',
                    instruction: 'Upload a file for the resource {0}',
                    title: 'Choose {0}'
                }
            }
        });
})();