(function () {
    'use strict';

    /**
     * This service is responsible for patient list data requests.
     */
    angular
        .module('therapist')
        .service('LeadsListService', LeadsListService);

    LeadsListService.$inject = ['$q', '$filter', 'RestResources'];

    function LeadsListService ($q, $filter, RestResources) {
        var service = {
            getLeads: getLeads,
            getCampaigns: getCampaigns
        };

        return service;

        function getCampaigns (clinicId) {
            var resource = RestResources.getClinicCampaignsResource(clinicId);
            var deferred = $q.defer();

            resource.get({}, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Gets all of the patients given a nextPageConfig object from a config service
         * Useful for mimicking the query set by a picker.
         *
         * @param {object} queryParams contains query parameters to pass to the resource
         * @returns {object}
         */
        function getLeads (queryParams) {
            var deferred = $q.defer();
            var resource = RestResources.getLeadsResource();

            resource.get(queryParams,
            function (result) {
                deferred.resolve(processResult(result));
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Processes the request result.
         *
         * @param {Array} result
         * @returns {Array}
         */
        function processResult (result) {
            var processedItems = [];

            angular.forEach(result.items, function (item) {
                // Update this object to determine what data is available in an export
                processedItems.push({
                    familiarCreated: $filter('date')(item.created, 'M/dd/yyyy H:mm'),
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: (item.person) ? item.person.username : 'N/A'
                });
            });

            return processedItems;
        }
    }
})();