(function () {
    'use strict';

    angular
            .module('therapist')
            .service('EpisodeTemplatesService', EpisodeTemplatesService);

    EpisodeTemplatesService.$inject = ['$q', 'RestResources'];

    function EpisodeTemplatesService ($q, RestResources) {
        var service = {
            get: get,
            getAll: getAll,
            save: save,
            update: update,
            remove: remove
        };

        return service;

        function get (clinicId, campaignId, episodeTemplateId) {
            var deferred = $q.defer();
            var resource = RestResources.getEOCTemplatesResources();

            resource.get({
                clinicId: clinicId,
                campaignId: campaignId,
                episodeTemplateId: episodeTemplateId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function getAll (clinicId, campaignId) {
            var deferred = $q.defer();
            var resource = RestResources.getEOCTemplatesResources();

            resource.query({
                clinicId: clinicId,
                campaignId: campaignId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function update (clinicId, campaignId, episodeTemplateId, episodeTemplate) {
            var deferred = $q.defer();
            var resource = RestResources.getEOCTemplatesResources();

            resource.update({
                clinicId: clinicId,
                campaignId: campaignId,
                episodeTemplateId: episodeTemplateId
            }, episodeTemplate, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function save (clinicId, campaignId, episodeTemplate) {
            var deferred = $q.defer();
            var resource = RestResources.getEOCTemplatesResources();

            resource.save({
                clinicId: clinicId,
                campaignId: campaignId
            }, episodeTemplate, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function remove (clinicId, campaignId, episodeTemplateId) {
            var deferred = $q.defer();
            var resource = RestResources.getEOCTemplatesResources(clinicId, campaignId, episodeTemplateId);

            resource.delete({}, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }
})();