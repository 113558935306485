(function () {
    'use strict';

    /**
     * This service handles data interactions for the resource materials
     *
     * @ngdoc service
     * @memberOf therapist
     * @name ResourceMaterialsService
     *
     * @param $q
     * @param RestResources
     */
    angular
        .module('therapist')
        .service('ResourceMaterialsService', ResourceMaterialsService);

    ResourceMaterialsService.$inject = ['$q', 'RestResources'];

    function ResourceMaterialsService($q, RestResources) {
        var self = this;

        /**
         * This functions will query for resource materials
         *
         * @param {string} clinicId clinic identifier
         * @returns {object} promise
         */
        self.getResourceMaterials = function (clinicId) {
            var deferred = $q.defer();
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);

            resource.get({
                clinicId: clinicId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function will retrieve a resource material by ID
         *
         * @param {string} resourceMaterialId the id for the resource material
         * @returns {object} promise
         */
        self.getResourceMaterial = function (clinicId, resourceMaterialId) {
            var deferred = $q.defer();
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);

            resource.get({
                resourceMaterialId: resourceMaterialId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function will retrieve a resource material by ID
         *
         * @param {string} resourceMaterialId the id for the resource material
         * @returns {object} promise
         */
        self.copyResourceMaterial = function (clinicId, resourceMaterialId) {
            var deferred = $q.defer();
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);

            resource.get({
                resourceMaterialId: resourceMaterialId,
                copy: true
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function creates a new object
         *
         * @returns {object} promise
         */
        self.getNewResourceMaterialObject = function () {
            var deferred = $q.defer();
            var resource = RestResources.getNewObjectResource();

            resource.save({}, {}, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function will create a resource material
         *
         * @param {object} obj a resource material object (new)
         * @returns {object} promise
         */
        self.saveResourceMaterial = function (clinicId, obj) {
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);
            var deferred = $q.defer();

            resource.save(obj, function (queryResult) {
                deferred.resolve(queryResult);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function will update a resource material
         *
         * @param {object} obj a resource material object (existing)
         * @param {string} resourceMaterialId the id for the resource material
         * @returns {object} promise
         */
        self.updateResourceMaterial = function (clinicId, obj, resourceMaterialId) {
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);
            var deferred = $q.defer();

            resource.update({
                resourceMaterialId: resourceMaterialId
            }, obj, function (queryResult) {
                deferred.resolve(queryResult);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function will delete a resource material
         *
         * @param {string} resourceMaterialId the id for the resource material
         * @returns {object} promise
         */
        self.deleteResourceMaterial = function (clinicId, resourceMaterialId) {
            var deferred = $q.defer();
            var resource = RestResources.getClinicResourceMaterialsResource(clinicId);

            resource.delete({
                resourceMaterialId: resourceMaterialId
            }, function () {
                deferred.resolve();
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        return self;
    }
})();