(function () {
    'use strict';

    /**
     *
     * @ngdoc controller
     * @memberOf therapist
     * @name EpisodeResourceMaterials
     *
     * @param $scope
     * @param $q
     * @param $modal
     * @param EpisodeResourceMaterialsService
     * @param EpisodeResourceMaterialsConfigService
     * @param ItemChooserSvc
     * @param IhhModalItemChooser
     * @param RestResources
     * @param _
     * @param personUtilities
     * @param Utilities
     */
    angular
        .module('therapist')
        .controller('EpisodeResourceMaterialsCtrl', EpisodeResourceMaterialsCtrl);

    EpisodeResourceMaterialsCtrl.$inject = ['$scope', '$q', '$modal', '$routeParams', 'EpisodeResourceMaterialsService', 'ModalResourceMaterialsConfigService', 'IhhModalItemChooser', 'RestResources', '_', 'personUtilities', 'EpisodeResourceMaterialsConstants', 'Utilities', 'ResourceMaterialsFactory'];

    function EpisodeResourceMaterialsCtrl($scope, $q, $modal, $routeParams, EpisodeResourceMaterialsService, ModalResourceMaterialsConfigService, IhhModalItemChooser, RestResources, _, personUtilities, EpisodeResourceMaterialsConstants, Utilities, ResourceMaterialsFactory) {
        var vm = this;
        var clinic = $scope.getClinic();
        var clinicId = clinic.id;
        var clinicGroupId = clinic.clinicGroup ? clinic.clinicGroup.id : null;
        var patientId = $scope.patientVm.patient.id;
        var episodeId = $scope.episode.id;

        var associateProfessionalId = $routeParams.associateProfessionalId;

        vm.isOpenEpisode = $scope.isOpenEpisode();

        vm.getResourceMaterialLinkUrl = function (item) {
            if (item) {
                return '#research/resource-materials/' + item.id;
            }
            else {
                return '#research/resource-materials';
            }
        };

        vm.getResourceMaterialThumbnailUrl = function (item) {
            return RestResources.getResourceMaterialThumbnailUrl(item);
        };

        vm.getMediaUrl = function (item) {
            return RestResources.getResourceMaterialMediaUrl(item, item.mediaName);
        };

        vm.getDefaultVideoThumbnailUrl = $scope.getDefaultVideoThumbnailUrl;

        vm.resourceMaterialPromise = false;

        vm.getUrl = getValidUrl;

        vm.addEpisodeResourceMaterial = function (item) {
            displayBusyIndicator();

            Utilities.confirmDialog(EpisodeResourceMaterialsConstants.resources.add.confirmText, function() {
                EpisodeResourceMaterialsService.saveEpisodeResourceMaterial(clinicId, episodeId, item.resourceMaterial).then(function (result) {
                    result.resourceMaterial.typeLabel = ResourceMaterialsFactory.getTypeLabel(result.resourceMaterial);
                    vm.resource.activeResourceMaterials.unshift(result);
                }, function (err) {
                    RestResources.reportRestError(EpisodeResourceMaterialsConstants.resources.add.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                });
            }, function () {
                hideBusyIndicator();
            });
        };

        vm.chooseResourceMaterial = function() {
            var prof = personUtilities.getClinicProfessionalId($scope.user.self, $scope.getClinic());
            var config = ModalResourceMaterialsConfigService.init(prof, clinicId, clinicGroupId, null, function(item) {
                displayBusyIndicator();

                EpisodeResourceMaterialsService.saveEpisodeResourceMaterial(clinicId, episodeId, item).then(function (result) {
                    result.resourceMaterial.typeLabel = ResourceMaterialsFactory.getTypeLabel(result.resourceMaterial);
                    vm.resource.activeResourceMaterials.unshift(result);
                }, function (err) {
                    RestResources.reportRestError(EpisodeResourceMaterialsConstants.resources.add.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                });

            }, patientId);

            config.maintainState($scope);

            IhhModalItemChooser.open(config);
        };

        vm.removeEpisodeResourceMaterial = function (episodeResourceMaterial) {
            displayBusyIndicator();

            Utilities.confirmDialog(EpisodeResourceMaterialsConstants.resources.remove.confirmText, function() {
                EpisodeResourceMaterialsService.removeEpisodeResourceMaterial(clinicId, episodeId, episodeResourceMaterial).then(function (result) {
                    _.remove(vm.resource.activeResourceMaterials, function (i) {
                        return i.id === result.id;
                    });
                    result.resourceMaterial.typeLabel = ResourceMaterialsFactory.getTypeLabel(result.resourceMaterial);
                    vm.resource.inactiveResourceMaterials.unshift(result);
                }, function (err) {
                    RestResources.reportRestError(EpisodeResourceMaterialsConstants.resources.remove.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                });
            }, function () {
                hideBusyIndicator();
            });
        };

        vm.displayVideoPreview = function (event, item) {
            event.stopPropagation();
            $modal.open({
                templateUrl: 'app/patients/patient/episodes/episode/resource-materials/episode-resource-material-modal.tpl.html',
                controller: 'EpisodeResourceMaterialModalCtrl',
                controllerAs: 'EpisodeResourceMaterialModalCtrlVm',
                bindToController: true,
                resolve: {
                    selectedItem: function () {
                        return item;
                    }
                }
            });
        };

        activate();

        function activate() {
            displayBusyIndicator();

            EpisodeResourceMaterialsService.getEpisodeResourceMaterials(clinicId, associateProfessionalId, episodeId).then(function (queryResult) {
                if (queryResult && queryResult.items) {
                    _.remove(queryResult.items, function (i) {
                        return !i.resourceMaterial;
                    });

                    var items = queryResult.items;

                    angular.forEach(items, function (i) {
                        i.resourceMaterial.typeLabel = ResourceMaterialsFactory.getTypeLabel(i.resourceMaterial);
                    });

                    var activeResourceMaterials = _.sortBy(_.filter(items, function (i) {
                        return i.attachedDate && !i.removedDate;
                    }), function (i) {
                        return !i.attachedDate;
                    });

                    var inactiveResourceMaterials = _.sortByOrder(_.filter(items, function (i) {
                        return i.attachedDate && i.removedDate;
                    }), ['removedDate'], ['desc']);

                    vm.resource = {
                        activeResourceMaterials: activeResourceMaterials || [],
                        inactiveResourceMaterials: inactiveResourceMaterials || []
                    };
                }
            }, function (err) {
                RestResources.reportRestError(EpisodeResourceMaterialsConstants.resources.get.errorString, err);
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        // uses a promise to display the busy indicator
        function displayBusyIndicator () {
            vm.resourceMaterialPromise = $q.defer().promise; // false promise
        }

        // clears the promise so that the busy indicator will no longer be displayed
        function hideBusyIndicator () {
            vm.resourceMaterialPromise = null;
        }

        function getValidUrl (url) {
            var validUrl = url;

            if (Utilities.isDefinedAndNotNull(url) && url.indexOf('://') < 0) {
                validUrl = "http://" + url;
            }

            return validUrl;
        }
    }
})();