(function () {
    'use strict';

    /**
     * This controller is responsible for resource materials.
     *
     * @ngdoc controller
     * @memberOf therapist
     * @name ResourceMaterialCtrl
     */
    angular
        .module('therapist')
        .controller('ResourceMaterialCtrl', ResourceMaterialCtrl);

    ResourceMaterialCtrl.$inject = ['$scope', '$routeParams', '$q', '$location', 'Utilities', 'ResourceMaterialsService', 'ResourceMaterialsConstants', 'RestResources', 'IhhMedia', 'personUtilities', 'TagsSvc', 'IhhArrays', 'TherapyObjectLifeCycle', 'ResourceMaterialsConfigService', 'CommonResourceMaterialsConstants'];

    function ResourceMaterialCtrl ($scope, $routeParams, $q, $location, Utilities, ResourceMaterialsService, ResourceMaterialsConstants, RestResources, IhhMedia, personUtilities, TagsSvc, IhhArrays, TherapyObjectLifeCycle, ResourceMaterialsConfigService, CommonResourceMaterialsConstants) {
        var vm = this;
        var confirmAwaySetup = false;
        var resourceMaterialId = $routeParams.resourceMaterialId;
        var clinic = $scope.user.clinic;
        var isCopy = resourceMaterialId === 'copy';
        var isNew = resourceMaterialId === 'new';
        var sourceResourceMaterialId = $routeParams.sourceId;

        RestResources.getClinicsResource().get({clinicId: clinic.id}, function(clinicResult) {
            if (clinicResult.clinicSpecialty && clinicResult.clinicSpecialty.resourceMaterialTagTypes) {
                vm.tagTypes = _.map(clinicResult.clinicSpecialty.resourceMaterialTagTypes, function(item) {
                    return {
                        tagName: item.tagName,
                        displayName: item.displayName,
                        tags: TagsSvc.getTags(item.tagName)
                    };
                });
            }
        });

        vm.lightResourceMaterialVideoThumbnailUrl = RestResources.getLightResourceMaterialVideoThumbnailUrl();
        vm.lightResourceMaterialPdfThumbnailUrl = RestResources.getLightResourceMaterialPdfThumbnailUrl();
        vm.lightResourceMaterialUrlThumbnailUrl = RestResources.getLightResourceMaterialUrlThumbnailUrl();
        vm.templateStrings = CommonResourceMaterialsConstants.type;
        vm.getUrl = getValidUrl;

        vm.isEdit = false;

        vm.categories = TagsSvc.getTags(ResourceMaterialsConstants.resources.tags);

        vm.getUserThumbnailUrl = $scope.getUserThumbnailUrl;

        vm.addTag = function(tag) {
            if (IhhArrays.indexOfById(vm.resourceTemp.tags, tag.id) === -1) {
                vm.resourceTemp.tags = vm.resourceTemp.tags || [];
                vm.resourceTemp.tags.push(tag);
                $scope.resourceMaterialsForm.$setDirty();
            }
        };

        vm.removeTag = function(tag) {
            IhhArrays.removeById(vm.resourceTemp.tags, tag.id);
            $scope.resourceMaterialsForm.$setDirty();
        };

        vm.cancel = function () {
            $scope.resourceMaterialsForm.$setPristine();

            if (isNew || isCopy) {
                $scope.back();
            }
            else {
                vm.resourceTemp = null;
                isEdit(false);
            }

            hideBusyIndicator();
        };

        vm.edit = function () {
            vm.resourceTemp = angular.copy(vm.resource);
            isEdit(true);

            vm.statuses = TherapyObjectLifeCycle.getStatuses(vm.resourceTemp);
            setupConfirmAway();
        };

        vm.save = function () {
            if (isNew || isCopy) {
                save();
            }
            else {
                update();
            }
        };

        vm.remove = function () {
            Utilities.confirmDialog(ResourceMaterialsConstants.resources.delete.confirmString, function () {
                displayBusyIndicator();

                ResourceMaterialsService.deleteResourceMaterial(clinic.id, resourceMaterialId).then(function() {
                    ResourceMaterialsConfigService.setDirty();
                }, function (err) {
                    RestResources.reportRestError(ResourceMaterialsConstants.resources.delete.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                    $scope.back();
                });
            });
        };

        vm.chooseThumbnail = function() {
            IhhMedia.chooseThumbnail(RestResources.getResourceMaterialMediaResource(resourceMaterialId), Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.title, [ResourceMaterialsConstants.media.types.thumbnail]), Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.instruction, [ResourceMaterialsConstants.media.types.thumbnail]), function(name) {
                $scope.resourceMaterialsForm.$setDirty();
                vm.resourceTemp.thumbnail = name;
            }, function(err) {
                RestResources.reportRestError(Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.errorString, [ResourceMaterialsConstants.media.types.thumbnail]), err);
            });
        };

        vm.deleteThumbnail = function() {
            delete vm.resourceTemp.thumbnail;
            $scope.resourceMaterialsForm.$setDirty();
        };

        vm.getMediaUrl = function () {
            return $scope.getResourceMaterialVideoUrl(vm.resource, vm.resource.mediaName);
        };

        vm.getMediaTempUrl = function () {
            return $scope.getResourceMaterialVideoUrl(vm.resourceTemp, vm.resourceTemp.mediaName);
        };

        vm.getMediaThumbnailUrl = function() {
            return $scope.getResourceMaterialThumbnailUrl(vm.resource);
        };

        vm.getMediaTempThumbnailUrl = function() {
            return $scope.getResourceMaterialThumbnailUrl(vm.resourceTemp);
        };

        vm.getStatus = function () {
            var resource = isEdit() ? vm.resourceTemp : vm.resource;
            return TherapyObjectLifeCycle.getStatus(resource);
        };

        vm.setStatus = function (state) {
            TherapyObjectLifeCycle.setStatus(vm.resourceTemp, state);
            $scope.resourceMaterialsForm.$setDirty();
        };

        vm.videoAspectOptions = ['Fit', 'Fill'];

        vm.videoAspectConstants = {
            fit: vm.videoAspectOptions[0],
            fill: vm.videoAspectOptions[1]
        };

        vm.getVideoAspect = function() {
            if (isEdit()) {
                if (vm.resourceTemp.videoAspectFill) {
                    return vm.videoAspectConstants.fill;
                }
                else {
                    return vm.videoAspectConstants.fit;
                }
            }
            else if (vm.resource.videoAspectFill) {
                return vm.videoAspectConstants.fill;
            }
            else {
                return vm.videoAspectConstants.fit;
            }
        };

        vm.setVideoAspect = function(state) {
            if (state === vm.videoAspectConstants.fit) {
                vm.resourceTemp.videoAspectFill = false;
                $scope.resourceMaterialsForm.$setDirty();
            }
            else if (state === vm.videoAspectConstants.fill) {
                vm.resourceTemp.videoAspectFill = true;
                $scope.resourceMaterialsForm.$setDirty();
            }
        };

        vm.isVideoAspectFill = function () {
            var resource = getModelForCurrentMode();

            return (resource) ? resource.videoAspectFill : false;
        };

        vm.chooseVideo = function () {
            var mediaResource = RestResources.getResourceMaterialMediaResource(vm.resourceTemp.id);
            var successFn = function(name, type, fileName) {
                vm.resourceTemp.mediaName = name;
                vm.resourceTemp.mediaType = type;
                vm.resourceTemp.mediaFileName = fileName;
                delete vm.resourceTemp.url;
                hideBusyIndicator();
            };
            var failureFn = function(err) {
                RestResources.reportRestError(Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.errorString, [ResourceMaterialsConstants.media.types.video]), err);
                hideBusyIndicator();
            };
            var uploadFn = function() {
                displayBusyIndicator();
            };

            IhhMedia.chooseVideo(mediaResource, Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.title, [ResourceMaterialsConstants.media.types.video]), Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.instruction, [ResourceMaterialsConstants.media.types.video]), successFn, failureFn, uploadFn);
        };

        vm.choosePdf = function () {
            var mediaResource = RestResources.getResourceMaterialMediaResource(vm.resourceTemp.id);
            var successFn = function(name, type, fileName) {
                vm.resourceTemp.mediaName = name;
                vm.resourceTemp.mediaType = type;
                vm.resourceTemp.mediaFileName = fileName;
                delete vm.resourceTemp.url;
                hideBusyIndicator();
            };
            var failureFn = function(err) {
                RestResources.reportRestError(Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.errorString, [ResourceMaterialsConstants.media.types.pdf]), err);
                hideBusyIndicator();
            };
            var uploadFn = function() {
                displayBusyIndicator();
            };

            IhhMedia.choosePdf(mediaResource, Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.title, [ResourceMaterialsConstants.media.types.pdf]), Utilities.interpolateStrings(ResourceMaterialsConstants.media.upload.instruction, [ResourceMaterialsConstants.media.types.pdf]), successFn, failureFn, uploadFn);
        };

        vm.removeMedia = function () {
            delete vm.resourceTemp.videoText;
            delete vm.resourceTemp.videoAspectFill;
            delete vm.resourceTemp.mediaName;
            delete vm.resourceTemp.mediaType;
            delete vm.resourceTemp.mediaFileName;
        };

        vm.removeUrl = function () {
            delete vm.resourceTemp.url;
        };

        vm.setUrl = function () {
            vm.resourceTemp.url = '';
        };

        vm.isUrlDefined = function (resource) {
            return resource && angular.isDefined(resource.url);
        };

        vm.getDefaultVideoThumbnailUrl = $scope.getDefaultVideoThumbnailUrl;

        vm.isMine = function() {
            return vm.resource ? personUtilities.isMine(vm.resource.author, $scope.getSelf(), clinic) : false;
        };

        vm.newVersion = function () {
            $location.path('/research/resource-materials/copy/' + resourceMaterialId);
        };

        vm.isVideo = function (mediaType) {
            mediaType = getMediaTypeForCurrentMode(mediaType);

            return mediaType === 'video/mp4' || mediaType === 'video/quicktime';
        };

        vm.isPdf = function (mediaType) {
            mediaType = getMediaTypeForCurrentMode(mediaType);

            return mediaType === 'application/pdf';
        };

        // brand is a scope value used by the ihh-navbar directive; displays in the second level navbar
        vm.getBrand = function () {
            var resource = getModelForCurrentMode();

            return (resource) ? resource.name : '';
        };

        /**
         * Returns the file name prefix appropriate for the file type.
         *
         * @returns {String} the file name prefix
         */
        vm.getFileNamePrefix = function () {
            return vm.isVideo() ? 'Video' : 'PDF';
        };

        activate();

        function activate () {
            displayBusyIndicator();

            if (isCopy) {
                ResourceMaterialsService.copyResourceMaterial(clinic.id, sourceResourceMaterialId).then(function (result) {
                    vm.resourceTemp = result;
                    vm.resourceTemp.name = (vm.resourceTemp.name || '') + ' (New)';
                    vm.resourceTemp.inHandHealth = false;
                    vm.resourceTemp.author = $scope.user.clinicProfessional;
                    vm.resourceTemp.clinic = clinic;

                    resourceMaterialId = result.id;
                    TherapyObjectLifeCycle.setDraft(vm.resourceTemp);
                    vm.statuses = TherapyObjectLifeCycle.getStatuses(vm.resourceTemp);
                    isEdit(true);
                }, function (err) {
                    RestResources.reportRestError(ResourceMaterialsConstants.resources.copy.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                });
            }
            else if (isNew) {
                ResourceMaterialsService.getNewResourceMaterialObject().then(function (result) {
                    vm.resourceTemp = {
                        id: result.id,
                        author: $scope.user.clinicProfessional,
                        clinic: clinic
                    };

                    resourceMaterialId = result.id;
                    vm.statuses = TherapyObjectLifeCycle.getStatuses(vm.resourceTemp);
                    isEdit(true);
                }, function (err) {
                    RestResources.reportRestError(ResourceMaterialsConstants.resources.create.errorString, err);
                    $scope.back();
                }).finally(function() {
                    hideBusyIndicator();
                });
            }
            else {
                ResourceMaterialsService.getResourceMaterial(clinic.id, resourceMaterialId).then(function (result) {
                    vm.resource = result;
                }, function (err) {
                    RestResources.reportRestError(ResourceMaterialsConstants.resources.get.errorString, err);
                }).finally(function () {
                    hideBusyIndicator();
                });
            }

            var unWatch = $scope.$watch('resourceMaterialsForm', function(form) {
                if (form) {
                    Utilities.confirmAway($scope, $scope.resourceMaterialsForm);
                    unWatch();
                }
            });
        }

        function save () {
            var resourceTemp = angular.copy(vm.resourceTemp);

            displayBusyIndicator();

            ResourceMaterialsService.saveResourceMaterial(clinic.id, formatTherapyObject(resourceTemp)).then(function (response) {
                vm.resourceTemp = null;
                vm.resource = angular.copy(response);
                $location.path('/research/resource-materials/' + response.id);
                ResourceMaterialsConfigService.setDirty();
                vm.statuses = TherapyObjectLifeCycle.getStatuses(vm.resource);
                isEdit(false);
            }, function (err) {
                RestResources.reportRestError(ResourceMaterialsConstants.resources.create.errorString, err);
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        function update () {
            var resourceTemp = angular.copy(vm.resourceTemp);

            displayBusyIndicator();

            ResourceMaterialsService.updateResourceMaterial(clinic.id, formatTherapyObject(resourceTemp), resourceTemp.id).then(function (response) {
                vm.resourceTemp = null;
                vm.resource = angular.copy(response);
                ResourceMaterialsConfigService.setDirty();
                vm.statuses = TherapyObjectLifeCycle.getStatuses(vm.resource);
                isEdit(false);
            }, function (err) {
                RestResources.reportRestError(ResourceMaterialsConstants.resources.update.errorString, err);
            }).finally(function () {
                hideBusyIndicator();
            });
        }

        function setupConfirmAway () {
            if (isEdit() && !confirmAwaySetup) {
                var unwatchFormInit = $scope.$watch(ResourceMaterialsConstants.resources.form, function(theForm) {
                    if (theForm) {
                        Utilities.confirmAway($scope, theForm);
                        confirmAwaySetup = true;

                        unwatchFormInit();
                    }
                });
            }
        }

        // uses a promise to display the busy indicator
        function displayBusyIndicator () {
            vm.resourceMaterialPromise = $q.defer().promise; // false promise
        }

        // clears the promise so that the busy indicator will no longer be displayed
        function hideBusyIndicator () {
            vm.resourceMaterialPromise = null;
        }

        function isEdit (val) {
            if (val === true || val === false) {
                vm.isEdit = val;

                if (!val && $scope.resourceMaterialsForm) {
                    $scope.resourceMaterialsForm.$setPristine();
                }
            }

            return vm.isEdit;
        }

        // useful for last second data manipulation
        function formatTherapyObject (therapyObject) {
            therapyObject.inHandHealth = false;
            therapyObject.author = $scope.user.clinicProfessional;
            therapyObject.clinic = $scope.getClinic();
            therapyObject.shared = false;

            return therapyObject;
        }

        function getModelForCurrentMode () {
            if (isEdit()) {
                return vm.resourceTemp;
            }

            return vm.resource;
        }

        function getMediaTypeForCurrentMode (mediaType) {
            if (!Utilities.isDefinedAndNotNull(mediaType)) {
                var resource = getModelForCurrentMode();
                if (Utilities.isDefinedAndNotNull(resource)) {
                    mediaType = resource.mediaType;
                }
            }
            return mediaType;
        }

        function getValidUrl () {
            var validUrl = vm.resource.url;

            if (Utilities.isDefinedAndNotNull(vm.resource.url) && vm.resource.url.indexOf('://') < 0) {
                validUrl = "http://" + vm.resource.url;
            }

            return validUrl;
        }
    }
})();
