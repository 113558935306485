(function() {
    'use strict';

    /**
     * A picker config for modal resource material pickers; typically with "adds on select" operation.
     *
     * @ngdoc service
     * @memberOf admin
     * @name ModalResourceMaterialsConfigService
     * @description service for modal resource material config
     */
    angular
        .module('therapist')
        .service('ModalResourceMaterialsConfigService', ModalResourceMaterialsConfigService);

    ModalResourceMaterialsConfigService.$inject = ['TherapyObjectLifeCycle', 'RestResources', 'ItemChooserSvc', 'ResourceMaterialsConstants', 'ResourceMaterialsFactory', 'TherapyObjectSvc'];

    function ModalResourceMaterialsConfigService(TherapyObjectLifeCycle, RestResources, ItemChooserSvc, ResourceMaterialsConstants, ResourceMaterialsFactory, TherapyObjectSvc) {
        var SAVE_STATE = 'ihh.chooser-state.resource-materials';
        var ERR_TITLE = 'Could not load the resource materials list';

        var config = null;

        this.init = function(therapistId, clinicId, clinicGroupId, containerTherapyObject, selectedFn, patientId) {
            config = ItemChooserSvc.createConfig(SAVE_STATE, RestResources.getClinicResourceMaterialsResource(clinicId), ERR_TITLE);
            TherapyObjectSvc.setupTherapyObjectPicker(config, ResourceMaterialsConstants.resources.name.singular, ResourceMaterialsConstants.resources.name.plural);

            config.setExtraQueryParams({complete: true});
            config.setCustomTemplate('/app/research/resource-materials/resource-materials-modal.tpl.html');

            config.setProcessItemFn(function (item) {
                item.thumbnailUrl = item.thumbnailUrl || RestResources.getResourceMaterialThumbnailUrl(item);
                item.typeLabel = ResourceMaterialsFactory.getTypeLabel(item);

                return item;
            });

            config.setSelectedFn(selectedFn);

            TherapyObjectSvc.refreshResourceTags(config, clinicId);
            TherapyObjectLifeCycle.refreshWho(config, patientId, therapistId, clinicId, clinicGroupId, containerTherapyObject);

            config.recallState();

            config.setDirty(true);
            config.nextPage();

            return config;
        };

        this.get = function() {
            return config;
        };
    }
})();
