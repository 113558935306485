(function () {
    'use strict';

    /**
     * This controller is responsible for the resource material list
     *
     * @ngdoc controller
     * @memberOf therapist
     * @name ResourceMaterialsListCtrl
     *
     * @param $scope
     * @param $location
     * @param ItemChooserSvc
     * @param ResourceMaterialsConfigService
     * @param ResourceMaterialsConstants
     * @param Utilities
     * @param personUtilities
     */
    angular
        .module('therapist')
        .controller('ResourceMaterialsListCtrl', ResourceMaterialsListCtrl);

    ResourceMaterialsListCtrl.$inject = ['$scope', '$location', 'ResourceMaterialsConfigService', 'ResourceMaterialsConstants', 'Utilities', 'personUtilities'];

    function ResourceMaterialsListCtrl ($scope, $location, ResourceMaterialsConfigService, ResourceMaterialsConstants, Utilities, personUtilities) {
        var clinic = $scope.user.clinic;
        var clinicGroupId = clinic.clinicGroup ? clinic.clinicGroup.id : null;

        var therapistId = personUtilities.getClinicProfessionalId($scope.getSelf(), clinic);
        $scope.ResourceMaterialsConfig = ResourceMaterialsConfigService.init(therapistId, clinic.id, clinicGroupId);
        $scope.ResourceMaterialsConfig.maintainState($scope);

        $scope.add = function() {
            $location.path(Utilities.interpolateStrings(ResourceMaterialsConstants.resources.link, [ResourceMaterialsConstants.resources.create.keyword]));
        };
    }
})();