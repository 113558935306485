(function () {
    'use strict';

    angular
        .module('therapist')
        .constant('ClinicLicenseConstants', {
            form: 'licenseForm',
            unrestrictedEditPeriodDays: 30,
            period: {
                month: 'month',
                year: 'year'
            },
            generic: {
                errorString: 'Something went wrong'
            },
            dryRun: {
                confirmDialog1: 'The prorated amount for this month that you will be charged to make this change is ',
                confirmDialog2: '  At renewal you will be charged the full subscription price',
                errorString: 'We were unable to get the prorated amount due for the change'
            },
            save: {
                noPaymentMethod: 'You must set a payment method before modifying the subscription',
                errorString: 'We were unable to save the subscription'
            },
            query: {
                errorString: 'We were unable to query for subscriptions'
            },
            remove: {
                errorString: 'We were unable to delete the subscription',
                confirmDialog: 'Are you sure that you wish to delete this subscription?'
            },
            override: {
                errorString: 'We were unable to override the existing subscription'
            },
            observers: {
                isInvoicedSubscription: 'admin:LicenseCtrl:isInvoicedSubscription',
                parentFormEdit: 'admin:LicenseCtrl:parentFormEdit',
                formReady: 'admin:LicenseCtrl:formReady',
                edit: 'admin:LicenseCtrl:edit',
                remove: 'admin:LicenseCtrl:remove',
                cancelChanges: 'admin:LicenseCtrl:cancelChanges'
            }
        });
})();