(function () {
    'use strict';

    /**
     * This service is responsible for patient import list data requests.
     */
    angular
        .module('therapist')
        .service('PatientImportDetailsListService', PatientImportDetailsListService);

    PatientImportDetailsListService.$inject = ['$q', 'RestResources', 'IhhFileChooser', 'FileUpload', 'Utilities'];

    function PatientImportDetailsListService ($q, RestResources, IhhFileChooser, FileUpload, Utilities) {
        var service = {
            getImports: getImports,
            createImport: createImport
        };

        var uploadConfig = {
            title: 'Upload Patient Import File',
            message: 'Choose a patient import file in the documented format',
            mediaType: 'csv'
        };

        return service;

        /**
         * Gets all of the patient imports .
         *
         * @param {object} queryParams contains query parameters to pass to the resource
         * @returns {object}
         */
        function getImports (queryParams) {
            var deferred = $q.defer();
            var resource = RestResources.getPatientImportsResource();

            resource.get(queryParams, function (result) {
                deferred.resolve(result.items);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function createImport(clinicId) {
            var deferred = $q.defer();
            var importResource = RestResources.getPatientImportsResource();
            var uploadResource = RestResources.getPatientImportUploadsResource();

            uploadResource.save({clinicId : clinicId}, {}, function (uploadInfo) {
                IhhFileChooser.open(uploadConfig, function(file) {
                    FileUpload.putFileToUrl(file, uploadInfo.uploadUrl, function( /* result */ ) {
                        importResource.save({clinicId : clinicId}, { inputId: uploadInfo.inputId }, function(result) {
                            deferred.resolve(result);
                        }, function(err) {
                            RestResources.reportRestError('Could not create import', err);
                            deferred.reject(err);
                        });
                    }, function(err) {
                        RestResources.reportRestError('Could not upload patient import file', err);
                        deferred.reject(err);
                    });
                }, function() {
                    deferred.resolve(null);
                });
            }, function (err) {
                RestResources.reportRestError('Could not get upload link for import file', err);
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }
})();