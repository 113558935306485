(function () {
    'use strict';

    /**
     * This service is responsible for patient list data requests.
     */
    angular
        .module('therapist')
        .service('PatientsListService', PatientsListService);

    PatientsListService.$inject = ['$q', '$filter', '$location', 'RestResources'];

    function PatientsListService ($q, $filter, $location, RestResources) {
        var service = {
            getPatients: getPatients
        };

        return service;

        /**
         * Gets all of the patients given a nextPageConfig object from a config service
         * Useful for mimicking the query set by a picker.
         *
         * @param {object} queryParams contains query parameters to pass to the resource
         * @returns {object}
         */
        function getPatients (queryParams) {
            var deferred = $q.defer();
            var resource = RestResources.getPatientsResource();

            resource.get(queryParams,
            function (result) {
                deferred.resolve(processResult(result));
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Processes the request result.
         *
         * @param {Array} result
         * @returns {Array}
         */
        function processResult (result) {
            var processedItems = [];

            angular.forEach(result.items, function (item) {
                // Update this object to determine what data is available in an export
                processedItems.push({
                    familiarCreated: $filter('date')(item.created, 'M/dd/yyyy H:mm'),
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: (item.person) ? item.person.username : 'N/A',
                    url: getPatientUrl(item)
                });
            });

            return processedItems;
        }

        /*
         * Creates a URL that deep links to the indicated patient in this web app.
         */
        function getPatientUrl(patient) {
            var result = $location.protocol() + '://' + $location.host();

            if ($location.protocol() === 'http') {
                result += ':' + $location.port();
            }

            result += '/#/patients/' + patient.id;

            return result;
        }
    }
})();