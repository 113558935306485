(function () {
    'use strict';

    /**
     * Controller for Clinics list.
     * @ngdoc controller
     * @memberOf admin
     * @name SentAlertsListCtrl
     * @param {Object} $scope the injected scope of the controller
     * @param {Object} $location the injected location service
     * @param {Object} SentAlertsChooserConfig the injected config for the clinics chooser
     */
    angular
        .module('therapist')
        .controller('SentAlertsListCtrl', SentAlertsListCtrl);

    SentAlertsListCtrl.$inject = ['$scope', '$location', 'SentAlertsChooserConfig'];

    function SentAlertsListCtrl($scope, $location, SentAlertsChooserConfig) {
        var self = this;

        $scope.alertConfig = SentAlertsChooserConfig.init($scope.user.clinic.id, $scope.isClinicAdmin());

        $scope.alertConfig.maintainState($scope);

        self.add = function() {
            $location.path('/sent-alerts/new');
        };
    }
})();