(function () {
    'use strict';

    /**
     * This service is responsible for episode resource material data access
     *
     * @ngdoc service
     * @memberOf therapist
     * @name EpisodeResourceMaterialsService
     * @description
     * Service for episode resource materials
     *
     * @param RestResources
     * @param $q
     */
    angular
        .module('therapist')
        .service('EpisodeResourceMaterialsService', EpisodeResourceMaterialsService);

    EpisodeResourceMaterialsService.$inject = ['RestResources', '$q'];

    function EpisodeResourceMaterialsService (RestResources, $q) {
        var self = this;

        /**
         * This function retrieves episode resource materials
         *
         * @param {type} clinicId
         * @param {type} episodeId
         * @returns {object} promise
         */
        self.getEpisodeResourceMaterials = function (clinicId, associateProfessionalId, episodeId) {
            var deferred = $q.defer();
            var resource = RestResources.getEpisodeResourceMaterialsResource(associateProfessionalId);

            resource.get({
                clinicId: clinicId,
                episodeId: episodeId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function saves an episode resource material
         *
         * @param {type} clinicId
         * @param {type} episodeId
         * @param {type} episodeResourceMaterial
         * @returns {object} promise
         */
        self.saveEpisodeResourceMaterial = function (clinicId, episodeId, episodeResourceMaterial) {
            var deferred = $q.defer();
            var resource = RestResources.getEpisodeResourceMaterialsResource();

            resource.save({
                clinicId: clinicId,
                episodeId: episodeId
            }, {
                attachedDate: now(),
                resourceMaterial: {
                    id: episodeResourceMaterial.id
                }
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        /**
         * This function removes an episode resource material
         *
         * @param {type} clinicId
         * @param {type} episodeId
         * @param {type} episodeResourceMaterial
         * @returns {object} promise
         */
        self.removeEpisodeResourceMaterial = function (clinicId, episodeId, episodeResourceMaterial) {
            var deferred = $q.defer();
            var resource = RestResources.getEpisodeResourceMaterialsResource();

            angular.extend(episodeResourceMaterial, {
                removedDate: now()
            });

            resource.update({
                clinicId: clinicId,
                episodeId: episodeId,
                episodeResourceMaterialId: episodeResourceMaterial.id
            }, episodeResourceMaterial, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        // returns the current time in ms since the unix epoch
        function now () {
            return new Date().getTime();
        }
    }
})();