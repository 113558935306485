(function() {
    'use strict';

    /**
     * A picker config for non-modal resource materials.
     *
     * @ngdoc service
     * @memberOf therapist
     * @name ResourceMaterialsConfigService
     */
    angular
        .module('therapist')
        .service('ResourceMaterialsConfigService', ResourceMaterialsConfigService);

    ResourceMaterialsConfigService.$inject = ['ResourceMaterialsConstants', 'TherapyObjectLifeCycle', 'TherapyObjectSvc', 'ItemChooserSvc', 'RestResources', 'ResourceMaterialsFactory'];

    function ResourceMaterialsConfigService(ResourceMaterialsConstants, TherapyObjectLifeCycle, TherapyObjectSvc, ItemChooserSvc, RestResources, ResourceMaterialsFactory) {
        var SAVE_STATE = 'ihh.chooser-state.resource-materials';
        var ERR_TITLE = 'Could not load the resource materials list';

        var config = null;

        this.init = function(therapistId, clinicId, clinicGroupId) {
            config = ItemChooserSvc.createConfig(SAVE_STATE, RestResources.getClinicResourceMaterialsResource(clinicId), ERR_TITLE);
            TherapyObjectSvc.setupTherapyObjectPicker(config, ResourceMaterialsConstants.resources.name.singular, ResourceMaterialsConstants.resources.name.plural);

            config.setTransclude(true);

            config.setProcessItemFn(function (item) {
                item.link = '#research/resource-materials/' + item.id;
                item.thumbnailUrl = item.thumbnailUrl ? item.thumbnailUrl : RestResources.getResourceMaterialThumbnailUrl(item);
                item.typeLabel = ResourceMaterialsFactory.getTypeLabel(item);

                return item;
            });

            TherapyObjectSvc.refreshResourceTags(config, clinicId);
            TherapyObjectLifeCycle.refreshWho(config, null, therapistId, clinicId, clinicGroupId);

            config.recallState();

            config.setDirty(true);
            config.nextPage();

            return config;
        };

        this.setDirty = function() {
            if (config) {
                config.setDirty();
            }
        };
    }
})();
