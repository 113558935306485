(function () {
    'use strict';

    /*
     * A directive to enable catching the onError event on an image tag so broken images don't stall incremental
     * loading.
     *
     * Initially used on the patient progress page to load large images in order from recent to earlier, and control the
     * concurrency of loading.
     */
    angular
        .module('therapist')
        .directive('ihhImageOnerror', function() {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    element.bind('error', function() {
                        scope.$apply(attrs.ihhImageOnload);

                    // usage: <img ng-src="src" ihh-image-onload="functionToCall() ihh-image-onerror="errorFunctionToCall()" />
                    });
                }
            };
        });
})();